<template>
    <div class="modal profile_modal share_playbook" v-if="modelValue">
        <div class="modal_container">
            <div class="modal_header border-bottom">
                <h1 class="m-0">{{ title }}<span>Share {{ title }}</span></h1>
                <button class="close_btn" @click="closeModal"><i class="fas fa-times"></i></button>
                <ul class="tab_row">
                    <li :class="shareTab === 'limited' ? 'active' : ''" @click="shareTab = 'limited'">Limited Share History</li>
                    <li :class="shareTab === 'unlimited' ? 'active' : ''" @click="shareTab = 'unlimited'">Unlimited Share History</li>
                </ul>
            </div>
            <div class="modal_body">
                <div v-show="shareTab === 'limited'" class="tab_wpr">
                    <div class="setting_wpr mt-3">
                        <div class="form_grp">
                            <div class="group_item">
                                <label class="input_label">Copy Share link:</label>
                                <div class="field_wpr has_suffix">
                                    <input type="text" v-model="limitedLink" readonly />
                                    <span class="suffix pointer" @click="copyLink('limitedLink')">Copy</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="result_wpr new">
                        <div class="actions">
                            <ul>
                                <li>
                                    <label for="selectAll" class="checkbox">
                                        <input type="checkbox" id="selectAll" v-model="selectAllAssets" :true-value="1" :false-value="0" @change="toggleSelectAll" hidden>
                                        <span><i class="fas fa-check"></i></span>
                                        <h5>{{ selectedLimitedAssets.length ? `${selectedLimitedAssets.length} Selected` : `Selected All ${limitedParams.per_page}`}}</h5>
                                    </label>
                                </li>
                                <li class="optionDrops contacts-tabs" @click="bulkAction = !bulkAction" v-if="selectedLimitedAssets.length" v-click-outside="closeBulkAction">
                                    Bulk Action<i class="fas fa-angle-down"></i>
                                    <div class="dropdown_wpr" :class="bulkAction ? 'active' : ''">
                                        <ul>
                                            <li @click="handleBulkDeleteAssets">Delete Assets</li>
                                            <li @click="handleBulkChangePermission(true)">Enable Assets</li>
                                            <li @click="handleBulkChangePermission()">Disable Assets</li>
                                        </ul>
                                    </div>
                                </li>
                                <li class="search_area ml-auto">
                                    <input type="text" @input="isTyping = true" v-model.trim="limitedParams.search" placeholder="Search"/>
                                    <button type="button" class="search_btn">
                                        <i class="fas fa-search"></i>
                                    </button>
                                </li>
                                <li class="optionDrops sort_list" @click="togglePerPageFilter('limited')"  v-click-outside="closePageFilter">
                                    Show {{ limitedParams.per_page }}<i class="fas fa-angle-down"></i>
                                    <page-filter v-model="limitedParams.per_page" :is-dropdown="true" :type="2" ref="limited-per-page-filter" />
                                </li>
                                <!-- <li class="list_info">
                                    {{ limitedShareHistory.from ? limitedShareHistory.from : 0 }} - {{ limitedShareHistory.to ? limitedShareHistory.to : 0 }} of <span>{{ limitedShareHistory.total ? limitedShareHistory.total : 0 }}</span>
                                </li> -->
                            </ul>
                        </div>
                        <div><line-loader v-if="loader" /></div>
                        <div class="scroll_table">
                            <table class="standard show_header">
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th>Name</th>
                                        <th>E-mail</th>
                                        <th>Date</th>
                                        <th>Status</th>
                                    </tr>
                                </thead>
                                <tbody v-if="limitedShareHistory.total">
                                    <tr v-for="(limitedHistory, l) in limitedShareHistory.data" :key="l">
                                        <td>
                                            <label :for="`limited-${l}`" class="checkbox">
                                                <input type="checkbox" :id="`limited-${l}`" :value="limitedHistory.id" v-model="selectedLimitedAssets" hidden>
                                                <span><i class="fas fa-check"></i></span>
                                            </label>
                                        </td>
                                        <td>
                                            <div class="user_wpr">
                                                <h4>{{ limitedHistory.user_name }}</h4>
                                            </div>
                                        </td>
                                        <td><div class="email_wpr">{{ limitedHistory.user_email }}</div></td>
                                        <td>{{ moment(limitedHistory.created_at).format('ll')  }}</td>
                                        <td>
                                            <label :for="`permission-${l}`" class="switch_option capsule_btn" @click="confirmAction = true">
                                                <input type="checkbox" :id="`permission-${l}`" :checked="!limitedHistory.is_revoke" @change=" togglePermission($event, limitedHistory.id)" hidden>
                                                <div><span></span></div>
                                            </label>
                                        </td>
                                    </tr>
                                </tbody>
                                <tbody v-else>
                                    <tr>
                                        <td colspan="5" class="text_center">
                                            <div class="empty_box">
                                                <img src="@/assets/images/empty_state.svg">
                                                <h2>No records found</h2>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="table_footer">
                        <ul>
                            <li>
                                {{ limitedShareHistory.from ? limitedShareHistory.from : 0 }} - {{ limitedShareHistory.to ? limitedShareHistory.to : 0 }} of <span>{{ limitedShareHistory.total ? limitedShareHistory.total : 0 }}</span>
                            </li>
                        </ul>
                    </div>
                    <div class="pagination pb-4 mt-4" v-show="limitedShareHistory.total">
                        <pagination v-model="limitedParams.page" :range-size="0" :pages="limitedShareHistory.last_page" @update:modelValue="handleLimitedPagination" />
                    </div>
                </div>
                <div v-show="shareTab === 'unlimited'" class="tab_wpr">
                    <div class="setting_wpr mt-3">
                        <div class="form_grp">
                            <div class="group_item">
                                <label class="input_label">Copy Share link:</label>
                                <div class="field_wpr has_suffix">
                                    <input type="text" v-model="unlimitedLink" readonly >
                                    <span class="suffix pointer" @click="copyLink('unlimitedLink')">Copy</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="result_wpr new">
                        <div class="actions">
                            <ul>
                                <li class="optionDrops sort_list" @click="togglePerPageFilter('unlimited')"  v-click-outside="closeUnlimitedPageFilter">
                                    Show {{ unlimitedParams.per_page }}<i class="fas fa-angle-down"></i>
                                    <page-filter v-model="unlimitedParams.per_page" :is-dropdown="true" :type="2" ref="unlimited-per-page-filter" />
                                </li>
                                <li class="search_area ml-auto">
                                    <input type="text" @input="isTypingUnlimited = true" v-model.trim="unlimitedParams.search" placeholder="Search"/>
                                    <button type="button" class="search_btn">
                                        <i class="fas fa-search"></i>
                                    </button>
                                </li>
                                <!-- <li class="list_info">
                                    {{ unlimitedShareHistory.from ? unlimitedShareHistory.from : 0 }} - {{ unlimitedShareHistory.to ? unlimitedShareHistory.to : 0 }} of <span>{{ unlimitedShareHistory.total ? unlimitedShareHistory.total : 0 }}</span>
                                </li> -->
                            </ul>
                        </div>
                        <div><line-loader v-if="loader" /></div>
                        <div class="scroll_table">
                            <table class="standard show_header">
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th>Name</th>
                                        <th>E-mail</th>
                                        <th>Date</th>
                                    </tr>
                                </thead>
                                <tbody v-if="unlimitedShareHistory.total">
                                    <tr v-for="(unlimitedHistory, u) in unlimitedShareHistory.data" :key="u">
                                        <td></td>
                                        <td>
                                            <div class="user_wpr">
                                                <h4>{{ unlimitedHistory.user_name }}</h4>
                                            </div>
                                        </td>
                                        <td><div class="email_wpr">{{ unlimitedHistory.user_email }}</div></td>
                                        <td>{{ moment(unlimitedHistory.created_at).format('ll')  }}</td>
                                    </tr>
                                </tbody>
                                <tbody v-else>
                                    <tr>
                                        <td colspan="4" class="text_center">
                                            <div class="empty_box">
                                                <img src="@/assets/images/empty_state.svg">
                                                <h2>No records found</h2>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="table_footer">
                        <ul>
                            <li>
                                {{ unlimitedShareHistory.from ? unlimitedShareHistory.from : 0 }} - {{ unlimitedShareHistory.to ? unlimitedShareHistory.to : 0 }} of <span>{{ unlimitedShareHistory.total ? unlimitedShareHistory.total : 0 }}</span>
                            </li>
                        </ul>
                    </div>
                    <div class="pagination pb-4 mt-4" v-show="unlimitedShareHistory.total">
                        <pagination v-model="unlimitedParams.page" :range-size="0" :pages="unlimitedShareHistory.last_page" @update:modelValue="handleUnlimitedPagination" />
                    </div>
                </div>
            </div>
            <div class="modal_footer">
                <button class="btn cancel_btn" @click="closeModal">Cancel</button>
            </div>
        </div>
    </div>
</template>

<script>
    import PageFilter from '@/components/PageFilter'
    import LineLoader from '@/components/LineLoader'
    import Pagination from '@hennge/vue3-pagination'
    import Toastr from '@/utils/Toastr'
    import moment from 'moment'
    import Swal from 'sweetalert2'
    import Helper from '@/utils/Helper'

    import { mapState, mapActions } from 'vuex'
    import { copyText } from 'vue3-clipboard'

    export default {
        name: 'Share Asset',

        data () {
            return {
                shareTab: 'limited',
                unlimitedLink: '',
                limitedLink: '',
                limitedParams: {
                    page: 1,
                    per_page: 5,
                    search: '',
                    id: '',
                    type: '',
                    share_count: 1,
                },
                unlimitedParams: {
                    page: 1,
                    per_page: 5,
                    search: '',
                    id: '',
                    type: '',
                    share_count: 0,
                },
                playbookSelected: true,
                bulkAction: false,
                selectedLimitedAssets: [],
                moment,
                isTyping: false,
                isTypingUnlimited: false,
                selectAllAssets: 0,
            }
        },

        props: {
            modelValue: Boolean,
            title: String,
            selectedAsset: Object,
            assetTitle: {
                type: String,
                default: null
            },
            module: {
                type: String,
                default: ''
            },
        },

        emit: ['update:modelValue'],

        components: {
            PageFilter,
            LineLoader,
            Pagination,
        },

        watch: {
            modelValue (value) {
                if (value) {
                    const vm = this;

                    vm.resetForm();

                    document.body.classList.add('modal-open');
                } else {
                    document.body.classList.remove('modal-open');
                }
            },

            'limitedParams.per_page' () {
                const vm = this;

                vm.limitedParams.page = 1;
                vm.getImportLogs(vm.limitedParams);
            },

            'unlimitedParams.per_page' () {
                const vm = this;

                vm.unlimitedParams.page = 1;
                vm.getImportLogs(vm.unlimitedParams);
            },

            'limitedParams.search' () {
                const vm = this;

                setTimeout(() => {
                    vm.isTyping = false;
                }, 1500);
            },

            isTyping (val) {
                const vm = this;

                if (!val) {
                    if (vm.limitedParams.search != null) {
                        if (vm.limitedParams.search.length >= 2 || vm.limitedParams.search.length === 0) {
                            vm.limitedParams.page = 1;
                            vm.getImportLogs(vm.limitedParams);
                        }
                    }
                }
            },

            'unlimitedParams.search' () {
                const vm = this;

                setTimeout(() => {
                    vm.isTypingUnlimited = false;
                }, 1500);
            },

            isTypingUnlimited (val) {
                const vm = this;

                if (!val) {
                    if (vm.unlimitedParams.search != null) {
                        if (vm.unlimitedParams.search.length >= 2 || vm.unlimitedParams.search.length === 0) {
                            vm.unlimitedParams.page = 1;
                            vm.getImportLogs(vm.unlimitedParams);
                        }
                    }
                }
            },

            selectedLimitedAssets (ids) {
                const vm  = this;

                if (Object.keys(vm.limitedShareHistory.data).length !== ids.length) {
                    vm.selectAllAssets = 0;
                } else {
                    vm.selectAllAssets = 1;
                }
            }
        },

        computed: mapState({
            loader: state => state.commonModule.loader,
            limitedShareHistory: state => state.commonModule.limitedShareHistory,
            unlimitedShareHistory: state => state.commonModule.unlimitedShareHistory,
        }),

        methods: {
            ...mapActions ({
                generateLink: 'commonModule/generateShareLink',
                getImportLogs: 'commonModule/getImportLogs',
                updatePermission: 'commonModule/updateSharePermission',
                deleteAssets: 'commonModule/deleteAssets',
            }),

            closeModal () {
                const vm = this;

                vm.$emit('update:modelValue', false)
            },

            togglePerPageFilter (value) {
                const vm = this;
                const filter = vm.$refs[`${value}-per-page-filter`];

                if (filter) {
                    filter.dropdown = !filter.dropdown;
                }
            },

            closePageFilter () {
                const vm = this;
                const filter = vm.$refs['limited-per-page-filter'];

                if (filter) {
                    filter.dropdown = false;
                }
            },

            closeUnlimitedPageFilter () {
                const vm = this;
                const filter = vm.$refs['unlimited-per-page-filter'];

                if (filter) {
                    filter.dropdown = false;
                }
            },

            closeBulkAction () {
                const vm = this;

                vm.bulkAction = false;
            },

            handleGenerateLink () {
                const vm = this;

                const share_count = vm.title == 'Habit Tracking' || vm.title == 'Progress Tracking' || vm.title == 'Client Tracking' ? 1 : 0;

                const params = {
                    id: vm.selectedAsset.id,
                    // code: vm.selectedAsset.code,
                    type: vm.module ? vm.module.replaceAll(' ', '-').toLowerCase() : vm.assetTitle ? vm.assetTitle.replaceAll(' ', '-').toLowerCase() : vm.title.replaceAll(' ', '-').toLowerCase(),
                    user_sid: vm.selectedAsset.user_sid,
                    asset_sid: vm.selectedAsset.asset_sid,
                    share_count
                };

                vm.generateLink(params).then((result) => {
                    vm.unlimitedLink = result;
                });

                params.share_count = share_count == 1 ? 0 : 1;

                vm.generateLink(params).then((result) => {
                    vm.limitedLink = result;
                });
            },

            copyLink (link) {
                const vm = this;

                copyText(vm[link], undefined, (error, event) => {
                    if (error) {
                        Toastr.error('Failed to copy link!');
                    } else {
                        Toastr.success('Link copied successfully!');
                    }
                });
            },

            resetForm () {
                const vm = this;

                vm.limitedParams.id     = vm.selectedAsset.id;
                vm.limitedParams.type   = vm.assetTitle ? vm.assetTitle.replaceAll(' ', '-').toLowerCase() : vm.title.replaceAll(' ', '-').toLowerCase();
                vm.unlimitedParams.id   = vm.selectedAsset.id;
                vm.unlimitedParams.type = vm.assetTitle ? vm.assetTitle.replaceAll(' ', '-').toLowerCase() : vm.title.replaceAll(' ', '-').toLowerCase();

                vm.getImportLogs(vm.limitedParams);
                vm.getImportLogs(vm.unlimitedParams);
                vm.handleGenerateLink();
            },

            handleLimitedPagination () {
                const vm = this;

                vm.getImportLogs(vm.limitedParams);
            },

            handleUnlimitedPagination () {
                const vm = this;

                vm.getImportLogs(vm.unlimitedParams);
            },

            togglePermission (e, id) {
                const vm = this;

                const message = !e.target.checked ? 'Doing so will disable this asset' : 'Doing so will enable this asset';
                const option = Helper.swalConfirmOptions('Are you sure?', `${message}`, 'Yes', false);

                Swal.fire(option).then((result) => {
                    if (result.isConfirmed) {
                        const form = {
                            id: [id],
                            is_revoke: e.target.checked ? 0 : 1,
                        };

                        vm.updatePermission(form).then((result) => {
                            vm.getImportLogs(vm.limitedParams);
                        });
                    } else {
                        e.target.checked = !e.target.checked;
                    }
                });
            },

            toggleSelectAll () {
                const vm = this;

                if (vm.selectAllAssets == 1) {
                    vm.limitedShareHistory.data.forEach((assets) => {
                        vm.selectedLimitedAssets.push(assets.id);
                    });
                } else {
                    vm.selectedLimitedAssets = [];
                }
            },

            handleBulkDeleteAssets () {
                const vm = this;

                if (vm.selectedLimitedAssets.length) {
                    const option = Helper.swalConfirmOptions('Are you sure?', 'You will not retrieved the selected records', 'Yes', false);

                    Swal.fire(option).then((result) => {
                        if (result.isConfirmed) {
                            const form = {
                                id: vm.selectedLimitedAssets,
                            };

                            vm.deleteAssets(form).then((result) => {
                                vm.selectedLimitedAssets = [];
                                vm.limitedParams.page   = 1;
                                vm.limitedParams.search = '';

                                vm.getImportLogs(vm.limitedParams);
                            });
                        }
                    });
                } else {
                    const option = Helper.swalWarningOptions('Alert!', 'Please select at least one in the records', 'Okay')

                    Swal.fire(option);
                }
            },

            handleBulkChangePermission (type = false) {
                const vm = this;

                if (vm.selectedLimitedAssets.length) {
                    const message = type ? 'This will enable the permission of the selected records' : 'This will disable the permission of the selected records';
                    const option  = Helper.swalConfirmOptions('Are you sure?', `${message}` , 'Yes', false);

                    Swal.fire(option).then((result) => {
                        if (result.isConfirmed) {
                            const form = {
                                id: vm.selectedLimitedAssets,
                                is_revoke: type ? 0 : 1
                            };

                            vm.updatePermission(form).then((result) => {
                                vm.selectedLimitedAssets = [];

                                vm.getImportLogs(vm.limitedParams);
                            });
                        }
                    });
                } else {
                    const option = Helper.swalWarningOptions('Alert!', 'Please select at least one in the records', 'Okay')

                    Swal.fire(option);
                }
            },
        }
    }
</script>

<style scoped>
    .share_playbook .modal_container {
        height: 640px;
    }

    .share_playbook .modal_container .modal_header {
        padding: 20px 30px 0;
    }

    .share_playbook .modal_container .modal_body {
        padding: 0 30px;
        flex-direction: column;
        background: #f5f5f5;
    }

    .share_playbook .tab_row {
        padding: 20px 0 0 0;
        display: flex;
        gap: 30px;
    }

    .share_playbook .tab_row li {
        padding-bottom: 12px;
        position: relative;
        cursor: pointer;
        font-size: 13px;
        line-height: 18px;
        font-weight: 400;
        color: #121525;
        overflow: hidden;
    }
    .share_playbook .tab_row li:after {
        position: absolute;
        content: '';
        left: -100%;
        bottom: 0;
        width: 100%;
        border-bottom: 1px solid #2F80ED;
        transition: all 0.3s ease-in-out;
    }

    .share_playbook .tab_row li.active {
        color: #2f7eed;
    }
    .share_playbook .tab_row li.active:after{
        left: 0;
    }

    .share_playbook .tab_wpr {
        width: 100%;
    }

    .share_playbook .result_wpr  {
        width: 100%;
    }
    .result_wpr.new .actions > ul{
        width: 100%;
    }

    table button {
        padding: 3px 10px;
        font-size: 11px;
        line-height: 13px;
        font-weight: 400;
        background: #f2a31e;
        border-radius: 10px;
        color: #fff;
        margin-left: 7px;
    }

    table button.success {
        background: #00aa14;
    }

    table button.failed {
        background: #f04438;
    }
    @media(max-width: 499px){
        .share_playbook .modal_container .modal_header {
            padding: 20px 20px 0 20px;
        }
        .share_playbook .modal_container .modal_body{
            padding: 0 20px;
        }
        .result_wpr.new .actions > ul li.search_area{
            flex: 1 0 200px !important;
            width: auto;
            order: -1;
        }
        .result_wpr.new .actions > ul li.sort_list{
            order: -1;
        }
        .result_wpr.new .actions > ul li.contacts-tabs{
            margin-left: auto;
        }
        .result_wpr.new .actions > ul > li.contacts-tabs .dropdown_wpr{
            right: 1px;
            left: auto;
        }
    }
</style>
